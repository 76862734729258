import TextComponent from "../CustomComponents/TextComponent.vue";
import RadioComponent from "../CustomComponents/RadioComponent.vue";
import DropdownComponent from "../CustomComponents/DropdownComponent.vue";
import EnumerationComponent from "../CustomComponents/EnumerationComponent.vue";
import UploadComponent from "../CustomComponents/UploadComponent.vue";

import CheckBoxComponent from "../CustomComponents/CheckBoxComponent.vue";
import RangeComponent from "../CustomComponents/rangeComponent.vue";

// Icons
import TextImage from "../../assets/radix-icons_component-1.svg";
import EmailImage from "../../assets/radix-icons_component-2.svg";
import RichTextImage from "../../assets/radix-icons_component-3.svg";
import PasswordImage from "../../assets/radix-icons_component-4.svg";
import EnumerationImage from "../../assets/radix-icons_component-5.svg";
import NumberImage from "../../assets/radix-icons_component-6.svg";
import DateImage from "../../assets/radix-icons_component-7.svg";
import MediaImage from "../../assets/radix-icons_component-8.svg";
import BooleanImage from "../../assets/radix-icons_component-9.svg";
import JsonImage from "../../assets/radix-icons_component-10.svg";
import RelationImage from "../../assets/radix-icons_component-11.svg";
import UIDImage from "../../assets/radix-icons_component-12.svg";
import ComponentImage from "../../assets/radix-icons_component-13.svg";
import CollectionImage from "../../assets/radix-icons_component-14.svg";

export const formJSON = {
  Text: {
    componentDescription: "Small or long text like title or description",
    componentIcon: TextImage,
    basicSettings: [
      {
        components: [
          {
            component: TextComponent,
            key: "ComponentLabel",
            type: "text",
            label: "Name",
            value: "",
            options: [],
            width: "50%",
            required: true,
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: RadioComponent,
            key: "ComponentType", // Ensure IDs are unique
            label: "Options",
            value: "",
            type: "radio",
            options: [
              {
                title: "Short Text",
                description:
                  "Best for titles, names, link(URL). It also enables exact search on the field.",
              },
              {
                title: "Long Text",
                description:
                  "Best for descriptions, biographies. Exact search is disabled.",
              },
            ],
            required: true,
          },
        ],
      },
      {
        components: [
          {
            component: CheckBoxComponent,
            key: "showComponentItself",
            id: "showComponentItself",
            label: "Show Component Itself",
            description: "Input field will be shown on the front-end",
            isChecked: false,
            placeholder: "",
          },
        ],
      },
    ],
    advancedSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            key: "ComponentDefaultValue",
            id: "input1",
            type: "text",
            label: "Default Value",
            value: "",
            options: [],
            placeholder: "",
          },
          {
            component: TextComponent,
            key: "ComponentPlaceholder",
            id: "input1",
            type: "text",
            label: "Placeholder",
            value: "",
            options: [],
            placeholder: "",
          },
          {
            component: TextComponent,
            key: "ComponentRegex",
            id: "input2",
            type: "text",
            label: "RegExp Pattern",
            value: "",
            options: [],
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            key: "IsRequired",
            id: "input2", // Ensure IDs are unique
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            isChecked: false,
            placeholder: "",
          },
          {
            component: CheckBoxComponent,
            key: "IsUnique",
            id: "input2", // Ensure IDs are unique
            label: "Unique Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            isChecked: false,
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: RangeComponent,
            key: "MinimumLength",
            width: "60%",
            label: "Minimum Length",
            type: "number",
            id: "input3",
            placeholder: "",
          },
          {
            component: RangeComponent,
            key: "MaximumLength",
            width: "60%",
            label: "Maximum Length",
            type: "number",
            id: "input3",
            placeholder: "",
          },
        ],
      },

      {
        components: [
          {
            component: CheckBoxComponent,
            key: "IsPrivate",
            id: "input4", // Ensure IDs are unique
            label: "Private Field",
            description: "This field will not show up in the API response",
            value: "",
            placeholder: "",
          },
        ],
      },
    ],
  },
  Email: {
    componentDescription: "Email field with validations format",
    componentIcon: EmailImage,
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            key: "ComponentLabel",
            id: "input1",
            type: "text",
            label: "Name",
            value: "",
            options: [],
            width: "50%",
            // rule: "No space is allowed for the name of the attribute",
            required: true,
          },
        ],
      },
      {
        components: [
          {
            component: CheckBoxComponent,
            key: "showComponentItself",
            id: "showComponentItself",
            label: "Show Component Itself",
            description: "Input field will be shown on the front-end",
            isChecked: false,
            placeholder: "",
          },
        ],
      },
    ],
    advancedSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            key: "ComponentDefaultValue",
            id: "input1",
            type: "text",
            label: "Default Value",
            value: "",
            options: [],
            placeholder: "",
          },
          {
            component: TextComponent,
            key: "ComponentPlaceholder",
            id: "input1",
            type: "text",
            label: "Placeholder",
            value: "",
            options: [],
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            key: "IsRequired",
            id: "input2", // Ensure IDs are unique
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
            placeholder: "",
          },
          {
            component: CheckBoxComponent,
            key: "IsUnique",
            id: "input2", // Ensure IDs are unique
            label: "Unique Field",
            description: "The field can have different values in each language",
            value: "",
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: RangeComponent,
            key: "MinimumLength",
            width: "60%",
            label: "Minimum Length",
            type: "number",
            id: "input3",
            placeholder: "",
          },
          {
            component: RangeComponent,
            key: "MaximumLength",
            width: "60%",
            label: "Maximum Length",
            type: "number",
            id: "input3",
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: CheckBoxComponent,
            key: "IsPrivate",
            id: "input4", // Ensure IDs are unique
            label: "Private Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
            placeholder: "",
          },
          {
            component: CheckBoxComponent,
            key: "IsLocalization",
            id: "input4", // Ensure IDs are unique
            label: "Enable Localization for this field",
            description: "The field can have different values in each language",
            value: "",
            placeholder: "",
          },
        ],
      },
    ],
  },
  "Rich Text": {
    componentDescription: "A rich text editor with formatting options",
    componentIcon: RichTextImage,
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            key: "ComponentLabel",
            id: "input1",
            type: "text",
            label: "Name",
            value: "",
            options: [],
            // rule: "No space is allowed for the name of the attribute",
            required: true,
          },
        ],
      },
    ],
    advancedSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            key: "ComponentDefaultValue",
            id: "input1",
            type: "text",
            label: "Default Value",
            value: "",
            options: [],
            placeholder: "",
          },
          {
            component: TextComponent,
            key: "ComponentPlaceholder",
            id: "input1",
            type: "text",
            label: "Placeholder",
            value: "",
            options: [],
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            key: "IsRequired",
            id: "input2", // Ensure IDs are unique
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
            placeholder: "",
          },
          {
            component: CheckBoxComponent,
            key: "IsLocalization",
            id: "input2", // Ensure IDs are unique
            label: "Enable Localization for this field",
            description: "The field can have different values in each language",
            value: "",
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: RangeComponent,
            key: "MinimumLength",
            width: "60%",
            label: "Minimum Length",
            type: "number",
            id: "input3",
            placeholder: "",
          },
          {
            component: RangeComponent,
            key: "MaximumLength",
            width: "60%",
            label: "Maximum Length",
            type: "number",
            id: "input3",
            placeholder: "",
          },
        ],
      },
      {
        components: [
          {
            component: CheckBoxComponent,
            key: "IsPrivate",
            id: "input4", // Ensure IDs are unique
            label: "Private Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
            width: "50%",
            placeholder: "",
          },
        ],
      },
    ],
  },
  Password: {
    componentDescription: "Password field with encryption",
    componentIcon: PasswordImage,
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            key: "ComponentLabel",
            id: "input1",
            type: "text",
            label: "Name",
            value: "",
            options: [],
            width: "50%",
            // rule: "No space is allowed for the name of the attribute",
            required: true,
          },
        ],
      },
      {
        components: [
          {
            component: CheckBoxComponent,
            key: "showComponentItself",
            id: "showComponentItself",
            label: "Show Component Itself",
            description: "Input field will be shown on the front-end",
            isChecked: true,
            placeholder: "",
            disabled: true,
          },
        ],
      },
    ],
    advancedSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            key: "ComponentDefaultValue",
            id: "input1",
            type: "text",
            label: "Default Value",
            value: "",
            options: [],
            // rule: "No space is allowed for the name of the attribute",
            placeholder: "",
          },
          {
            component: TextComponent,
            key: "ComponentPlaceholder",
            id: "input1",
            type: "text",
            label: "Placeholder",
            value: "",
            options: [],
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            key: "IsRequired",
            id: "input2", // Ensure IDs are unique
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
            placeholder: "",
          },
          {
            component: CheckBoxComponent,
            key: "IsLocalization",
            id: "input2", // Ensure IDs are unique
            label: "Enable Localization for this field",
            description: "The field can have different values in each language",
            value: "",
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: RangeComponent,
            key: "MinimumLength",
            width: "60%",
            label: "Minimum Length",
            type: "number",
            id: "input3",
            placeholder: "",
          },
          {
            component: RangeComponent,
            key: "MaximumLength",
            width: "60%",
            label: "Maximum Length",
            type: "number",
            id: "input3",
            placeholder: "",
          },
        ],
      },
      {
        components: [
          {
            component: CheckBoxComponent,
            key: "IsPrivate",
            id: "input4", // Ensure IDs are unique
            label: "Private Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
            width: "50%",
            placeholder: "",
          },
        ],
      },
    ],
  },
  Number: {
    componentDescription: "Numbers (integer, decimal)",
    componentIcon: NumberImage,
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            key: "ComponentLabel",
            id: "input1",
            type: "text",
            label: "Name",
            value: "",
            options: [],
            // rule: "No space is allowed for the name of the attribute",
            placeholder: "",
            required: true,
          },
          {
            component: DropdownComponent,
            key: "Format",
            id: "input1",
            label: "Number Format",
            options: [{ title: "integer" }, { title: "decimal" }],
            placeholder: "",
            required: true,
          },
        ],
      },
    ],
    advancedSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            key: "ComponentDefaultValue",
            id: "input1",
            type: "number",
            label: "Default Value",
            value: "",
            options: [],
            placeholder: "",
          },
          {
            component: TextComponent,
            key: "ComponentPlaceholder",
            id: "input1",
            type: "text",
            label: "Placeholder",
            value: "",
            options: [],
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            key: "IsRequired",
            id: "input2", // Ensure IDs are unique
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
            placeholder: "",
          },
          {
            component: CheckBoxComponent,
            key: "IsUnique",
            id: "input2", // Ensure IDs are unique
            label: "Unique Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: RangeComponent,
            key: "MinimumValue",
            width: "60%",
            label: "Minimum Value",
            type: "number",
            id: "input5",
            placeholder: "",
          },
          {
            component: RangeComponent,
            key: "MaximumValue",
            width: "60%",
            label: "Maximum Value",
            type: "number",
            id: "input5",
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: CheckBoxComponent,
            id: "input4", // Ensure IDs are unique
            key: "IsPrivate",
            label: "Private Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
            placeholder: "",
          },
          {
            component: CheckBoxComponent,
            key: "IsLocalization",
            id: "input4", // Ensure IDs are unique
            label: "Enable Localization for this field",
            description: "The field can have different values in each language",
            value: "",
            placeholder: "",
          },
        ],
      },
    ],
  },
  Enumeration: {
    componentDescription: "List of values, then pick one",
    componentIcon: EnumerationImage,
    basicSettings: [
      {
        components: [
          {
            component: TextComponent,
            key: "ComponentLabel",
            id: "input1",
            type: "text",
            label: "Name",
            value: "",
            options: [],
            width: "50%",
            // rule: "No space is allowed for the name of the attribute",
            required: true,
          },
        ],
      },
      {
        components: [
          {
            component: EnumerationComponent,
            key: "ComponentOptions",
            id: "input2",
            label: "Values (one value per line)",
            width: "50%",
            placeholder: "Ex:\nmorning\nnoon\nevening",
            value: "",
            options: [],
            required: true,
          },
        ],
      },
      {
        components: [
          {
            key: "AllowMultiple",
            component: CheckBoxComponent,
            id: "input3", // Ensure IDs are unique
            label: "Allow Multiple",
            description: "Enable it to allow multiple option to choose.",
            value: "",
            placeholder: "",
          },
        ],
      },
    ],
    advancedSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: DropdownComponent,
            key: "ComponentDefaultValue",
            id: "input1",
            label: "Default Value",
            options: [],
            placeholder: "",
            value: "",
            defaultValue: "",
          },
          {
            component: TextComponent,
            key: "ComponentPlaceholder",
            id: "input1",
            type: "text",
            label: "Placeholder",
            value: "",
            options: [],
            placeholder: "",
          },
          // {
          //   component: TextComponent,
          //   label: "Name override for GraphQL",
          //   rule: "Allows you to override the default generated name for GraphQL",
          //   placeholder: "",
          //   value: "",
          // },
        ],
      },
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            key: "IsRequired",
            id: "input2", // Ensure IDs are unique
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
            placeholder: "",
            options: [],
          },
          {
            component: CheckBoxComponent,
            key: "IsLocalization",
            id: "input2", // Ensure IDs are unique
            label: "Enable Localization for this field",
            description: "The field can have different values in each language",
            value: "",
            placeholder: "",
            options: [],
          },
        ],
      },
      {
        components: [
          {
            component: CheckBoxComponent,
            key: "IsPrivate",
            id: "input3", // Ensure IDs are unique
            label: "Private Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
            placeholder: "",
            options: [],
            width: "50%",
          },
        ],
      },
    ],
  },
  Lists: {
    componentDescription: "List of values with all the options",
    componentIcon: EnumerationImage,
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            key: "ComponentLabel",
            id: "input1",
            type: "text",
            label: "Name",
            value: "",
            options: [],
            width: "50%",
            // rule: "No space is allowed for the name of the attribute",
            required: true,
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: RadioComponent,
            key: "ComponentType", // Ensure IDs are unique
            label: "Options",
            value: "",
            type: "radio",
            options: [
              {
                title: "DropDown",
                description:
                  "Best for selecting predefined options from a list.",
              },
              {
                title: "Check Box",
                description:
                  "Best for selecting one or multiple options from a list.",
              },
              {
                title: "Radio Button",
                description:
                  "Best for selecting a single option from a predefined list.",
              },
              {
                title: "Array",
                description:
                  "Best for managing multiple values or items in a structured list.",
              },
            ],
            required: true,
          },
        ],
      },
    ],
    advancedSettings: [
      {
        components: [
          {
            component: TextComponent,
            key: "ComponentPlaceholder",
            id: "input1",
            type: "text",
            label: "Placeholder",
            value: "",
            options: [],
            width: "50%",
            placeholder: "",
          },
        ],
      },
      {
        components: [
          {
            component: EnumerationComponent,
            key: "ComponentDefaultValue",
            id: "input2",
            label: "Default Values (one value per line)",
            width: "50%",
            placeholder: "Ex:\nmorning\nnoon\nevening",
            value: "",
            options: [],
            required: false,
          },
        ],
      },
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            key: "IsRequired",
            id: "input2", // Ensure IDs are unique
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
            placeholder: "",
          },
          {
            component: CheckBoxComponent,
            key: "IsLocalization",
            id: "input4", // Ensure IDs are unique
            label: "Enable Localization for this field",
            description: "The field can have different values in each language",
            value: "",
            placeholder: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: CheckBoxComponent,
            key: "IsPrivate",
            id: "input4", // Ensure IDs are unique
            label: "Private Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
            placeholder: "",
          },
        ],
      },
    ],
  },
  Date: {
    componentDescription: "A date picker with hours, minutes and seconds",
    componentIcon: DateImage,
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            key: "ComponentLabel",
            id: "input1",
            type: "text",
            label: "Name",
            value: "",
            // rule: "No space is allowed for the name of the attribute",
            placeholder: "",
            required: true,
          },
          {
            component: DropdownComponent,
            key: "Format",
            id: "input2",
            label: "Format",
            type: "dateFormatDropdown",
            options: [
              { title: "Date", id: "date" },
              { title: "Date & Time", id: "datetime-local" },
              { title: "Time", id: "time" },
              { title: "Months", id: "month" },
            ],
            value: "",
            placeholder: "",
            required: true,
          },
        ],
      },
    ],
    advancedSettings: [
      {
        components: [
          {
            component: TextComponent,
            key: "ComponentDefaultValue",
            id: "input1",
            type: "date",
            label: "Default Value",
            value: "",
            width: "50%",
          },
        ],
      },
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            key: "IsRequired",
            id: "input2", // Ensure IDs are unique
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
          },
          {
            component: CheckBoxComponent,
            key: "IsUnique",
            id: "input2", // Ensure IDs are unique
            label: "Unique Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: CheckBoxComponent,
            key: "IsPrivate",
            id: "input3", // Ensure IDs are unique
            label: "Private Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
          },
          {
            component: CheckBoxComponent,
            key: "IsLocalization",
            id: "input3", // Ensure IDs are unique
            label: "Enable Localization for this field",
            description: "The field can have different values in each language",
            value: "",
          },
        ],
      },
    ],
  },
  Media: {
    componentDescription: "Files like images, videos, etc",
    componentIcon: MediaImage,
    basicSettings: [
      {
        components: [
          {
            component: TextComponent,
            key: "ComponentLabel",
            id: "input1",
            type: "text",
            label: "Name",
            // rule: "No space is allowed for the name of the attribute",
            width: "50%",
            placeholder: "",
            value: "",
            options: [],
            required: true,
          },
        ],
      },
      {
        style: "display: flex",
        components: [
          {
            component: RadioComponent,
            key: "ComponentType",
            id: "input2", // Ensure IDs are unique
            label: "Options",
            value: "",
            type: "radio",
            options: [
              {
                title: "Multiple Media",
                description:
                  "Best for titles name, link(URL). It also enables exact search on the field.",
              },
              {
                title: "Single Media",
                description:
                  "Best for descriptions, Biography, Exact search is disabled.",
              },
            ],
            placeholder: "",
            required: true,
          },
        ],
      },
      // {
      //   components: [
      //     {
      //       component: UploadComponent,
      //       key: "UploadedFile",
      //       id: "input3",
      //       value: null,
      //       type: "file",
      //       label: "Upload Files",
      //       width: "50%",
      //     },
      //   ],
      // },
      {
        components: [
          {
            component: CheckBoxComponent,
            key: "showComponentItself",
            id: "showComponentItself",
            label: "Show Component Itself",
            description: "Input field will be shown on the front-end",
            isChecked: false,
            placeholder: "",
          },
        ],
      },
    ],
    advancedSettings: [
      {
        components: [
          {
            component: EnumerationComponent,
            key: "ComponentOptions",
            id: "input1",
            label: "Enter allowed types of media (one value per line)",
            placeholder: "Ex:\nAudio\nFiles\nImages\nVideo",
            value: "",
            options: [],
            width: "50%",
          },
        ],
      },
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            key: "IsRequired",
            id: "input2", // Ensure IDs are unique
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
            placeholder: "",
            options: [],
          },
          {
            component: CheckBoxComponent,
            key: "IsLocalization",
            id: "input2", // Ensure IDs are unique
            label: "Enable Localization for this field",
            description: "The field can have different values in each language",
            value: "",
            placeholder: "",
            options: [],
          },
        ],
      },
      {
        components: [
          {
            component: CheckBoxComponent,
            key: "IsPrivate",
            id: "input3", // Ensure IDs are unique
            label: "Private Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
            placeholder: "",
            options: [],
            width: "50%",
          },
        ],
      },
    ],
  },
  Boolean: {
    componentDescription: "True or False",
    componentIcon: BooleanImage,
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            key: "ComponentLabel",
            id: "input1",
            type: "text",
            label: "Name",
            // rule: "No space is allowed for the name of the attribute",
            required: true,
          },
          // {
          //   component: DropdownComponent,
          //   id: "input2",
          //   label: "Type",
          //   options: [],
          //   value: "",
          //   placeholder: "",
          // },
        ],
      },
    ],
    advancedSettings: [
      {
        components: [
          {
            component: DropdownComponent,
            key: "ComponentDefaultValue",
            id: "input1",
            label: "Default Value",
            options: [{ title: "True" }, { title: "False" }],
            value: "",
            placeholder: "",
            width: "50%",
          },
        ],
      },
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            key: "IsRequired",
            id: "input2", // Ensure IDs are unique
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
          },
          {
            component: CheckBoxComponent,
            key: "IsLocalization",
            id: "input2", // Ensure IDs are unique
            label: "Enable Localization for this field",
            description: "The field can have different values in each language",
            value: "",
          },
        ],
      },
      {
        components: [
          {
            component: CheckBoxComponent,
            key: "IsPrivate",
            id: "input3", // Ensure IDs are unique
            label: "Private Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
            width: "50%",
          },
        ],
      },
    ],
  },
  JSON: {
    componentDescription: "Data in JSON format",
    componentIcon: JsonImage,
    basicSettings: [
      {
        components: [
          {
            component: TextComponent,
            key: "ComponentLabel",
            id: "input1",
            type: "text",
            label: "Name",
            // rule: "No space is allowed for the name of the attribute",
            width: "50%",
            required: true,
          },
        ],
      },
    ],
    advancedSettings: [
      {
        style: "display : flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            key: "IsRequired",
            id: "input1",
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
            placeholder: "",
            options: [],
          },
          {
            component: CheckBoxComponent,
            key: "IsLocalization",
            id: "input1",
            label: "Enable Localization for this field",
            description: "The field can have different values in each language",
            value: "",
            placeholder: "",
            options: [],
          },
        ],
      },
      {
        components: [
          {
            component: CheckBoxComponent,
            key: "IsPrivate",
            id: "input2",
            label: "Private Field",
            description:
              "You won't be able to create an entry if there is an existing entry with identical content",
            value: "",
            placeholder: "",
            options: [],
            width: "50%",
          },
        ],
      },
    ],
  },
  Relation: {
    componentDescription: "Refers to a Collection Type",
    basicSettings: [],
  },
  UID: {
    componentDescription: "Unique Identifier",
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            label: "Name",
            type: "text",
            placeholder: "e.g. slug, seoUrl, CanonicalUrl",
            // rule: "No space is allowed for the name of the attribute",
            options: [],
            value: "",
            required: true,
          },
          {
            component: DropdownComponent,
            label: "Attached Field",
            options: [],
            value: "",
            placeholder: "",
            required: true,
          },
        ],
      },
    ],
  },
  Components: {
    componentDescription: "Group of fields that you can repeat or reuse",
    componentIcon: ComponentImage,
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: RadioComponent,
            key: "ComponentType",
            id: "input1", // Ensure IDs are unique
            label: "Options",
            value: "",
            type: "radio",
            options: [
              {
                title: "Create a new component",
                description:
                  "A component is shared across types and components, it will be available and accessible everywhere.",
                conditionalComponents: [
                  {
                    component: TextComponent,
                    id: "input1",
                    key: "ComponentLabel",
                    type: "text",
                    label: "Display Name",
                    required: true,
                    value: "",
                    placeholder: "",
                  },
                ],
              },
              {
                title: "Using an existing component",
                description:
                  "Reuse a component already craeted to keep your data consistent across content-types.",
                conditionalComponents: [
                  {
                    component: DropdownComponent,
                    key: "ComponentOption",
                    id: "input2",
                    label:
                      "Select a category or enter a name to create a new one",
                    value: "",
                    required: true,
                    options: [
                      {
                        title: "Component 1",
                      },
                      {
                        title: "Component 2",
                      },
                      {
                        title: "Component 3",
                      },
                    ],
                  },
                ],
              },
            ],
            required: true,
          },
        ],
      },
    ],
    advancedSettings: [
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            id: "input1",
            key: "IsRequired",
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
            options: [],
          },
          {
            component: CheckBoxComponent,
            id: "input1",
            label: "Private Field",
            key: "IsPrivate",
            description: "This field will not show up in the API response",
            value: "",
            options: [],
          },
        ],
      },
      {
        components: [
          {
            component: CheckBoxComponent,
            id: "input2",
            key: "IsLocalization",
            label: "Enable Localization for this field",
            description:
              "This field can have different values in each language",
            value: "",
            options: [],
            width: "50%",
          },
        ],
      },
    ],
  },
  Collections: {
    componentDescription: "collections",
    componentIcon: CollectionImage,
    basicSettings: [
      {
        style: "display: flex",
        components: [
          // {
          //   component: RadioComponent,
          //   id: "input1", // Ensure IDs are unique
          //   label: "Options",
          //   key: "ComponentType",
          //   value: "",
          //   type: "radio",
          //   options: [
          //     {
          //       title: "Create a new component",
          //       description:
          //         "A component is shared across types and components, it will be available and accessible everywhere.",
          //       conditionalComponents: [
          //         {
          //           component: TextComponent,
          //           id: "input1",
          //           key:"componentName",
          //           type: "text",
          //           label: "Display Name",
          //           value: "",
          //         },
          //       ],
          //     },
          //     {
          //       title: "Using an existing component",
          //       description:
          //         "Reuse a component already craeted to keep your data consistent across content-types.",
          //       conditionalComponents: [
          // {
          //   component: DropdownComponent,
          //   key: "selectedComponent",
          //   id: "input2",
          //   label:
          //     "Select a category or enter a name to create a new one",
          //   value: "",
          //   options: [],
          // },
          //       ],
          //     },
          //   ],
          // },
          {
            component: DropdownComponent,
            key: "selectedComponent",
            id: "input2",
            label: "Select a category or enter a name to create a new one",
            value: "",
            required: true,
            options: [{ title: "sample 1" }],
          },
        ],
      },
    ],
    advancedSettings: [
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            id: "input1",
            key: "IsRequired",
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
            options: [],
          },
          {
            component: CheckBoxComponent,
            id: "input1",
            label: "Private Field",
            key: "IsPrivate",
            description: "This field will not show up in the API response",
            value: "",
            options: [],
          },
        ],
      },
      {
        components: [
          {
            component: CheckBoxComponent,
            id: "input2",
            label: "Enable Localization for this field",
            key: "IsLocalization",
            description:
              "This field can have different values in each language",
            value: "",
            options: [],
            width: "50%",
          },
        ],
      },
    ],
  },
  "Components Update": {
    componentDescription: "Group of fields that you can repeat or reuse",
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            id: "input1",
            key: "ComponentLabel",
            type: "text",
            label: "Display Name",
            value: "",
            placeholder: "",
          },
        ],
      },
    ],
    advancedSettings: [
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            id: "input1",
            key: "IsRequired",
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
            options: [],
          },
          {
            component: CheckBoxComponent,
            id: "input1",
            label: "Private Field",
            key: "IsPrivate",
            description: "This field will not show up in the API response",
            value: "",
            options: [],
          },
        ],
      },
      {
        components: [
          {
            component: CheckBoxComponent,
            id: "input2",
            key: "IsLocalization",
            label: "Enable Localization for this field",
            description:
              "This field can have different values in each language",
            value: "",
            options: [],
            width: "50%",
          },
        ],
      },
    ],
  },
  "Collections Update": {
    componentDescription: "Group of fields that you can repeat or reuse",
    basicSettings: [
      {
        style: "display: flex",
        components: [
          {
            component: TextComponent,
            id: "input1",
            key: "ComponentLabel",
            type: "text",
            label: "Display Name",
            value: "",
            placeholder: "",
          },
        ],
      },
    ],
    advancedSettings: [
      {
        style: "display: flex",
        componentLabel: "Settings",
        components: [
          {
            component: CheckBoxComponent,
            id: "input1",
            key: "IsRequired",
            label: "Required Field",
            description:
              "You won't be able to create an entry if this field is empty",
            value: "",
            options: [],
          },
          {
            component: CheckBoxComponent,
            id: "input1",
            label: "Private Field",
            key: "IsPrivate",
            description: "This field will not show up in the API response",
            value: "",
            options: [],
          },
        ],
      },
      {
        components: [
          {
            component: CheckBoxComponent,
            id: "input2",
            key: "IsLocalization",
            label: "Enable Localization for this field",
            description:
              "This field can have different values in each language",
            value: "",
            options: [],
            width: "50%",
          },
        ],
      },
    ],
  },
};
